import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from "@clerk/clerk-react";
import { createClient } from "@supabase/supabase-js";

const supabaseUrl = 'https://kblwtpyjxodiirnbxllv.supabase.co'
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImtibHd0cHlqeG9kaWlybmJ4bGx2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjQ2OTA1MTUsImV4cCI6MjA0MDI2NjUxNX0.8wtfY-RW5x_d8qHEQdy30ZBSojPz0VW7QSC8TcGti9Q'

const supabase = createClient(supabaseUrl, supabaseKey);

const NotionAuthRedirect = () => {
  const navigate = useNavigate();
  const [hasFetched, setHasFetched] = useState(false);
  const [userReady, setUserReady] = useState(false); // New state to monitor when user is ready
  const { user, isLoaded } = useUser(); // isLoaded will indicate if the user data is ready

  useEffect(() => {
    // Monitor when user data is fully loaded and user exists
    if (isLoaded && user) {
      setUserReady(true); // Set userReady to true when user is loaded
    }
  }, [isLoaded, user]);

  useEffect(() => {
    if (!userReady || hasFetched) return;  // Wait until the user data is fully loaded and token hasn't been fetched yet

    const storeUserId = async (notionAuthValue: string) => {
      if (user) {
        try {
          console.log("Attempting to store user ID:", user.id, "with notionAuthValue:", notionAuthValue);

          const { data, error } = await supabase
            .from('notion-timeline-auth')
            .insert({ user_id: user.id, notion_auth: notionAuthValue });

          if (error) {
            throw new Error(error.message);
          }

          console.log("User ID stored successfully:", data);
        } catch (error) {
          console.error("Error during Supabase insert:", error);
        }
      } else {
        console.log("User is undefined or null");
      }
    };

    const fetchToken = async () => {
      if (hasFetched) return;  // Prevent multiple submissions
      setHasFetched(true);

      try {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (!code) {
          throw new Error("Authorization code not found");
        }

        const response = await fetch(`http://localhost:5000/oauth/callback?code=${code}`);
        
        if (!response.ok) {
          throw new Error('Failed to exchange authorization code for access token');
        }

        const data = await response.json();
        console.log('Access token data:', data['access_token']);
        await storeUserId(data['access_token']);
        navigate('/');

      } catch (error) {
        console.error('Error during authorization:', error);
        navigate('/error');
      }
    };

    fetchToken();
  }, [hasFetched, navigate, user, userReady]); // Run when userReady changes

  return (
    <div>
      <h1>Authorizing...</h1>
    </div>
  );
};

export default NotionAuthRedirect;
