import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './LandingPage';
import NotionAuth from './NotionAuth';
import { ClerkProvider, useUser } from '@clerk/clerk-react';
import NotionAuthRedirect from './NotionRedirect';
import RegistrationPage from './RegistrationPage';
import PageSelect from './PageSelect';

const PUBLISHABLE_KEY = "pk_test_dXAtdGVybWl0ZS01Mi5jbGVyay5hY2NvdW50cy5kZXYk";

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

function App() {
  return (
    <ClerkProvider publishableKey={PUBLISHABLE_KEY} afterSignOutUrl="/">
      <MainApp />
    </ClerkProvider>
  );
}

function MainApp() {
  const { user } = useUser();

  useEffect(() => {
    if (user) {
      console.log("User Information:", user);
    } else {
      console.log("No user is logged in.");
    }
  }, [user]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        {!user && <Route path="/login" element={<RegistrationPage />} />}
        {user && <Route path="/login" element={<NotionAuth />} />}
        <Route path="/register" element={<NotionAuth />} />
        <Route path="/redirect" element={<NotionAuthRedirect />} />
        <Route path="/page-select" element={<PageSelect />} />
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;
