import React, { useEffect, useState } from "react";
import './app/globals.css';
import { NavBar } from "./Navbar";
import { Footer } from "./Footer";
import { useUser } from "@clerk/clerk-react";
import { createClient } from "@supabase/supabase-js";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
  } from "./@/components/ui/select"
// page?.properties?.Name?.title?.[0]?.plain_text || page?.properties?.Title?.title?.[0]?.plain_text
// ^^ Subpages if needed, don't remove this

const supabaseUrl = 'https://kblwtpyjxodiirnbxllv.supabase.co'
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImtibHd0cHlqeG9kaWlybmJ4bGx2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjQ2OTA1MTUsImV4cCI6MjA0MDI2NjUxNX0.8wtfY-RW5x_d8qHEQdy30ZBSojPz0VW7QSC8TcGti9Q'

const supabase = createClient(supabaseUrl, supabaseKey);

const PageSelect: React.FC = () => {
    const { user } = useUser();
    const [notionAuth, setNotionAuth] = useState('')
    const [pages, setPages] = useState<any[]>([])


      useEffect(() => {
        const retrieveUserNotionAuth = async () => {
            if (user) {
              try {
      
                const { data, error } = await supabase
                  .from('notion-timeline-auth')
                  .select('notion_auth').eq('user_id', user.id);
    
                if (error) {
                  return null
                }
    
                return data
    
              } catch (error) {
                return null
              }
            } else {
              return null
            }
          };

        const fetchNotionAuth = async () => {
            if (user) {
                const notionAuth = await retrieveUserNotionAuth();
                setNotionAuth(notionAuth?.[0]?.notion_auth)
            }
        };

        fetchNotionAuth();
    }, [user]);

    useEffect(() => {
        const fetchPages = async () => {
            try {
                const response = await fetch('http://localhost:5000/api/list-pages', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ notionToken: notionAuth }),
                });
    
                const data = await response.json();
                setPages(data.results || []);
            } catch (error) {
                console.error("Error fetching pages:", error);
            }
        };

        if (notionAuth) {
            fetchPages();
        }
    }, [notionAuth]);

  return (
    /*
    {pages.map((page, index) => (
                            <li key={index}>
                                {page?.parent?.type === "workspace" && (page?.properties?.title?.title?.[0]?.text?.content || page?.title?.[0]?.text?.content)}
                            </li>
    */
    <div className="flex flex-col min-h-screen">
            <NavBar />
            <div className="p-4">
                <h1 className="text-2xl font-bold">Notion Pages</h1>
                {pages.length > 0 ? (
                    <Select>
                    <SelectTrigger className="w-full max-w-md">
                        <SelectValue placeholder="Select a page" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                        <SelectLabel>Notion Workspace Pages</SelectLabel>
                        {pages.map((page, index) => (
                            page?.parent?.type === "workspace" && <SelectItem key={index} value={page.id}>
                                {(page?.properties?.title?.title?.[0]?.text?.content || page?.title?.[0]?.text?.content)}
                            </SelectItem>
                        ))}
                        </SelectGroup>
                    </SelectContent>
                </Select>
                ) : (
                    <p>No pages found.</p>
                )}
            </div>
            <Footer />
        </div>
  );
};

export default PageSelect;
