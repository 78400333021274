import { CIcon } from '@coreui/icons-react';
import { cibNotion } from '@coreui/icons';

export const Footer: React.FC = () => {
    return (
        <footer className="py-10 bg-[#f9f9fd] text-black">
  <div className="container mx-auto flex flex-col md:flex-row justify-between items-center text-center md:text-left">
    <div className="flex flex-col items-center md:items-start mb-4 md:mb-0">
      <div className="flex items-center mb-4">
        <CIcon icon={cibNotion} size="xl" className="w-10 h-10 mr-2" />
        <h1 className="text-xl font-semibold">NoteTimelines</h1>
      </div>
      <p className="text-gray-500">Notion and the Notion logo are trademarks of Notion Labs, Inc., and are used here with permission.</p>
    </div>

    <div className="mt-4 md:mt-0 text-gray-500">
      <p>&copy; 2024 NoteTimelines. All rights reserved.</p>
    </div>
  </div>
</footer>
    );
};
