import { ReactComponent as MagicTimeIcon } from './assets/magichourglass.svg';
import { Link } from "react-router-dom";
import { Button } from "./@/components/ui/button";

export const NavBar: React.FC = () => {
return (
    <header className="py-4 border-b">
    <div className="container mx-auto flex justify-between items-center">
    <div className="flex items-center space-x-2">
        <MagicTimeIcon width="40" height="40" />
        <a href="/" className="text-xl leading-none font-semibold text-center pt-4 md:pt-0 md:max-w-full text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600 inline-block relative">
        NoteTimelines
        <span className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-yellow-400 to-orange-600 transform scale-x-0 origin-left transition-transform duration-300 hover:scale-x-100"></span>
        </a>
        </div>
        <div className="flex space-x-4">
        <Link to="/login">
            <Button variant="login">Login</Button>
        </Link>
            <Button variant="timeline">Create Timeline</Button>
        </div>
        </div>
    </header>
    )
}