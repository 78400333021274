import { Card, CardHeader, CardTitle, CardContent } from "./@/components/ui/card";

export const Features: React.FC = () => {
    return (
<section className="py-24 bg-[#f9f9fb]">  {/* Increased padding from py-16 to py-24 */}
  <div className="container mx-auto text-center">
    <h2 className="text-3xl font-bold mb-16">Features</h2>  {/* Adjusted spacing for more breathing room */}
    <div className="grid grid-cols-1 md:grid-cols-3 gap-12">  {/* Increased gap between cards */}
      <Card className="shadow-lg hover:shadow-xl transition-shadow">  {/* Added shadow for more depth */}
        <CardHeader>
          <CardTitle>Flexible Pages</CardTitle>
        </CardHeader>
        <CardContent>
          <p>Create and organize pages effortlessly.</p>
        </CardContent>
      </Card>
      <Card className="shadow-lg hover:shadow-xl transition-shadow">
        <CardHeader>
          <CardTitle>Powerful Databases</CardTitle>
        </CardHeader>
        <CardContent>
          <p>Manage tasks, notes, and more with databases.</p>
        </CardContent>
      </Card>
      <Card className="shadow-lg hover:shadow-xl transition-shadow">
        <CardHeader>
          <CardTitle>Collaborate in Real-Time</CardTitle>
        </CardHeader>
        <CardContent>
          <p>Work together with your team seamlessly.</p>
        </CardContent>
      </Card>
    </div>
  </div>
</section>
    );
};